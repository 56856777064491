<template>
	<div class="download-app">
        <h2 v-html="tips"></h2>
	</div>
</template>

<script>
	export default {
        data(){
            return {
              tips: "頁面跳轉中，請稍後……",   
            }
        },
		created: function() {
            var browser = {
                versions: function () {
                    var u = navigator.userAgent, app = navigator.appVersion;
                    return { //移动终端浏览器版本信息
                        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
                        iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
                        iPad: u.indexOf('iPad') > -1//是否iPad
                    };
                }()
            }
            if (browser.versions.iPhone || browser.versions.iPad || browser.versions.ios) {
                window.location.href = "https://apps.apple.com/hk/app/%E5%A4%A7%E9%86%AB/id1488584152";
            } else {
                this.tips = '請點擊右上角的<span style="color:red">在瀏覽器中打開</span>進行下載。';
                window.location.href = "https://play.google.com/store/apps/details?id=com.ectcm.flutter_dayi";
            }
        }
    };
</script>

<style lang="scss" scoped>
    .download-app {
        width: 1000px;
        margin: 0 auto;
    }

    .download-app > h2 {
        font-size: 24px;
        font-weight: 400;
        color: #232323;
        margin-top: 80px;
    }
</style>
